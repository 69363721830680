// libs
import { configureStore, Store } from "@reduxjs/toolkit";
// reducers
import authReducer from "src/redux/slices/auth/reducer";
import modalsReducer from "src/redux/slices/modals/reducer";
import appointmentReducer from "src/redux/slices/appointment/reducer";
import permissionsReducer from "src/redux/slices/permissions/reducer";
import reviewReducer from "src/redux/slices/review/reducer";
import variablesReducer from "src/redux/slices/variables/reducer";
import dynamicReducer from "src/redux/slices/dynamic/reducer";
import pathReducer from "src/redux/slices/path/reducer";
// redux
import { setDynamicState } from "src/redux/slices/dynamic/reducer";
// types
import EWhiteList from "src/redux/white-list";

let store: Store;

const initialState = {};

function initStore(preloadedState: any = initialState) {
  return configureStore({
    reducer: {
      [EWhiteList.AUTH]: authReducer,
      [EWhiteList.MODALS]: modalsReducer,
      [EWhiteList.APPOINTMENT]: appointmentReducer,
      [EWhiteList.PERMISSIONS]: permissionsReducer,
      [EWhiteList.REVIEW]: reviewReducer,
      [EWhiteList.VARIABLES]: variablesReducer,
      [EWhiteList.DYNAMIC]: dynamicReducer,
      [EWhiteList.PATH]: pathReducer,
    },
    preloadedState,
    devTools: process.env.NODE_ENV === "development",
  });
}

export const initializeStore = (preloadedDynamicState?: { DYNAMIC: any } | undefined) => {
  let _store = store ?? initStore(preloadedDynamicState);

  if (preloadedDynamicState && store) {
    store.dispatch(setDynamicState(preloadedDynamicState.DYNAMIC));
  }

  if (!store) store = _store;

  return _store;
};

export const useStore = initializeStore;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: { user: userReducer, ... }
export type AppDispatch = typeof store.dispatch;
